
<script setup lang="ts">
    import { ref, watch } from "vue";
    import { useAsyncComponent } from "o365-vue-utils";
    import { isMobile } from "o365.GlobalState.ts";

    export interface IProps {
        title?: string;
        size?: "sm" | "md" | "lg";
        minHeight?: string;
    };

    const OModal = useAsyncComponent("o365-ui-components/OModal");
    const MBottomSheet = useAsyncComponent("o365-mobile/MBottomSheet");

    const props = withDefaults(defineProps<IProps>(), { size: "md" });
    const model = defineModel<boolean>();

    const modal = ref<any>();

    watch(model, () => {
        if (model.value) {
            modal.value?.show();
        } else {
            modal.value?.hide();
        }
    });
</script>

<template>
    <template v-if="isMobile">
        <MBottomSheet v-model="model" :title="title" :size="size">
            <div class="flex-grow-1 d-flex flex-column">
                <div class="flex-grow-1 p-3 d-flex flex-column overflow-y-auto">
                    <slot />
                </div>
                <template v-if="$slots.footer">
                    <div class="flex-shrink-0 p-3 d-flex justify-content-end align-items-center gap-2">
                        <slot name="footer" />
                    </div>
                </template>
            </div>
        </MBottomSheet>
    </template>
    <template v-else>
        <OModal ref="modal" @show="model = true" @hide="model = false">
            <div class="modal-dialog" :class="size ? `modal-${size}` : ''">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">{{ title }}</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body p-3 d-flex flex-column" :style="{ 'min-height': minHeight }">
                        <slot />
                    </div>
                    <template v-if="$slots.footer">
                        <div class="modal-footer">
                            <div class="d-flex justify-content-end align-items-center gap-2">
                                <slot name="footer" />
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </OModal>
    </template>
</template>
